import React from 'react'

import Footer from '~/components/Footer/FooterGql'
import WeOfferSection from '~/components/landing-common/WeOfferSection'
import ExplanationSection from '~/components/landing-common/ExplanationSection'
import HeroSection from '~/components/landing-common/HeroSection/HeroSectionGql'
import TakePartSection from '~/components/landing-common/TakePartSection/TakePartSectionGql'
import FestivalMissionSection from '~/components/landing-common/FestivalMissionSection'
import InstagramSection from '~/components/landing-common/InstagramSection'
import VoucherSection from '~/components/landing-common/VoucherSection'
import LogosSection from '~/components/LogosSection/LogosSectionGql'

const OFFERS = {
  offer_1: 'book',
  offer_2: 'percent',
  offer_3: 'instagram',
  offer_4: 'group',
  offer_5: 'time',
  offer_6: 'healthy-life'
}

const SOCIALS = [
  {
    src: 'instagram.png',
    href: 'https://www.instagram.com/restaurant_week_polska',
    alt: 'Ikona Instagrama'
  },
  {
    src: 'facebook.png',
    href: 'https://www.facebook.com/events/2676230865757378/?acontext=%7B%22event_action_history%22%3A[%7B%22mechanism%22%3A%22search_results%22%2C%22surface%22%3A%22search%22%7D]%7D',
    alt: 'Ikona Facebooka'
  },
]

export default class Index extends React.Component {

  render () {
    const { isVoucher } = this.props

    return <div>
      <div className='container'>
        <HeroSection slides={['hero-korw.png']} />
      </div>
      <div className='container'>
        <ExplanationSection />
      </div>
      {isVoucher &&
      <div className='container'>
        <VoucherSection />
      </div>}
      <FestivalMissionSection
        paragraphs={['paragraph_1', 'paragraph_2', 'paragraph_3']}
        decorator='decorator-korw.jpg'
        festivalCode='korw'
        withSignature={false}
      />
      <div className='container'>
        <WeOfferSection offers={OFFERS} bold={[4, 6]} />
      </div>
      <InstagramSection socials={SOCIALS} />
      <div className='container'>
        <LogosSection loadImmediately />
      </div>
      {!isVoucher && <TakePartSection modifiers='index' withRespect={false} />}
      <Footer />
    </div>
  }
}
