import React from 'react'

import withLandingNamespaces from '~/hoc/withLandingNamespaces'

import HomepageSectionWrapper from '~/components/landing-common/HomepageSectionWrapper'
import ImageTag from '~/components/ImageTag'

@withLandingNamespaces()
export default class FestivalMissionSection extends React.Component {

  render () {
    const { t, paragraphs, decorator, festivalCode, withSignature = true } = this.props

    return (
      <div className='festival-mission-section'>
        <div className='festival-mission-section__content container'>
          <div className='festival-mission-section__text-box'>
            <HomepageSectionWrapper
              modifiers='mission'
              title={t('festival_mission_section.title')}
              description={t('festival_mission_section.description')}
            >
              {paragraphs.map(paragraph =>
                <p
                  key={paragraph}
                  className='festival-mission-section__paragraph'
                  dangerouslySetInnerHTML={{ __html: t(`festival_mission_section.${paragraph}`) }}
                />
              )}
              {withSignature && <div className='festival-mission-section__signature'>
                {t('festival_mission_section.signature')}
              </div>}
            </HomepageSectionWrapper>
          </div>
          <div className='festival-mission-section__photo-container'>
            <ImageTag
              className='festival-mission-section__photo-image'
              src={`assets/landing-${festivalCode || 'common'}/mission/photo.jpg`}
            />
          </div>
          {decorator &&
            <div className='festival-mission-section__decorator-container'>
              <ImageTag
                className='festival-mission-section__decorator'
                src={`assets/${decorator}`}
              />
            </div>
          }
        </div>
      </div>
    )
  }
}
